//@font-face {
//  font-family: 'ProductSans';
//  src: url('../fonts/product-sans/Product Sans Regular.ttf') format('truetype');
//  font-weight: 400;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'ProductSans';
//  src: url('../fonts/product-sans/Product Sans Bold.ttf') format('truetype');
//  font-weight: 600;
//  font-style: normal;
//}
//
//// Fonts
//$font-family: 'ProductSans', 'Open Sans', Verdana, Tahoma;
//$regular: 400;
//$bold: 600;

@font-face {
  font-family: 'URW Geometric';
  src: url('../fonts/urw-geometric/URWGeometric-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'URW Geometric';
  //src: url('../fonts/urw-geometric/URWGeometric-ExtraBold.ttf') format('truetype');
  //font-weight: 800;
  src: url('../fonts/urw-geometric/URWGeometric-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

// Fonts
$font-family: 'URW Geometric', Arial, 'Open Sans', Verdana, Tahoma;
$regular: 500;
$bold: bold;
