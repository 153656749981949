@import './../../../index';

.badge {
  @include fs-lh(10);
  color: $pure-white;
  font-weight: $bold;
  background-image: linear-gradient(to bottom, $vx_deep-pink, $pink-2);
  border-radius: 8px;
  padding: 1px 3px;
  letter-spacing: 1px;
}
