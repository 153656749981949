@import './../../../index';

button.button,
a.button {
  padding: 0 40px;
  border-radius: 8px;
  text-transform: uppercase;
  text-align: center;
  @include fs-lh(14);
  font-weight: $bold;
  background: none;
  height: 35px;
  display: inline-block;
  &-primary {
    color: $pure-white;
    background-image: linear-gradient(to bottom, $secondary-red, $primary);
    &:hover {
      color: $pure-white;
    }
    &:disabled {
      opacity: 0.7;
      background: $secondary-black;
    }
  }
  &-secondary {
    color: $pure-white;
    background-image: linear-gradient(
      to bottom,
      $purple-secondary,
      $purple-main
    );
    &:hover {
      color: $pure-white;
    }
    &:disabled {
      opacity: 0.7;
      background: $secondary-black;
    }
  }
  &-tertiary {
    background-color: $black;
    color: $white;
    &:hover {
      color: $white;
    }
    @include fs-lh(12);
    &.more::after {
      background-image: url('./../../../assets/icons/icon-common-white-arrow.svg') !important;
    }
  }
  &-ghost {
    background-color: $pure-white;
    //color: $black-1;
    color: $primary;
    &:hover {
      //color: $black-1;
      color: $primary;
    }
    //border: 1px solid $black-1;
    border: 1px solid $primary;
    width: 105px;
    padding: 0;
    &:disabled {
      opacity: 0.7;
      border-color: $secondary-black;
      color: $secondary-black;
    }
  }
  &.full-width {
    width: 100%;
  }
  &.auto-width {
    width: auto;
  }
  //&:disabled {
  //  opacity: 0.5;
  //}
  &.medium {
    height: 40px;
  }
  &.large {
    height: 45px;
  }
  &.action-link {
    padding: 0;
    color: $primary;
    font-weight: $bold;
    @include fs-lh(10);
    height: unset;
    &.secondary {
      color: $purple-main;
    }
  }
  &.more {
    &::after {
      content: ' ';
      background-image: url('./../../../assets/icons/icon-common-red-arrow.svg');
      background-size: 9px 9px;
      height: 9px;
      width: 9px;
      display: inline-flex;
      padding-left: 2px;
      background-repeat: no-repeat;
      background-position: right;
    }
  }
  &.more-white {
    &::after {
      content: ' ';
      background-image: url('./../../../assets/icons/icon-common-white-arrow.svg');
      background-size: 9px 9px;
      height: 9px;
      width: 9px;
      display: inline-flex;
      padding-left: 2px;
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}

.button-counter {
  &-ghost {
    border-radius: 8px;
    text-transform: uppercase;
    text-align: center;
    //background-color: $pure-white;
    //border: solid 1px $black-1;
    border: solid 1px $primary;
    font-weight: $bold;
    @include fs-lh(14);
    height: 35px;
    width: 105px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .decrement,
    .increment {
      color: $primary;
      //color: $black-1;
      padding: 10px 15px;
      cursor: pointer;
      //display: inline-block;
    }
    .counter {
      color: $black;
      //padding: 10px 8px;
      display: inline-block;
    }
  }
  &.disabled {
    opacity: 0.7;
    border-color: $secondary-black;
  }
  &.large {
    height: 45px;
  }
  &.full-width {
    width: 100%;
  }
}

a.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.desktop-container .button {
  @include fs-lh(16);
  cursor: pointer;
}

//https://icons8.com/cssload/en/horizontal-bars
//<div id="circleG">
//<div id="circleG_1" className="circleG"></div>
//<div id="circleG_2" className="circleG"></div>
//<div id="circleG_3" className="circleG"></div>
//</div>
//#circleG{
//  width:52px;
//  margin:auto;
//}
//
//.circleG{
//  background-color:rgb(242,77,85);
//  float:left;
//  height:11px;
//  margin-left:6px;
//  width:11px;
//  animation-name:bounce_circleG;
//  -o-animation-name:bounce_circleG;
//  -ms-animation-name:bounce_circleG;
//  -webkit-animation-name:bounce_circleG;
//  -moz-animation-name:bounce_circleG;
//  animation-duration:0.7775s;
//  -o-animation-duration:0.7775s;
//  -ms-animation-duration:0.7775s;
//  -webkit-animation-duration:0.7775s;
//  -moz-animation-duration:0.7775s;
//  animation-iteration-count:infinite;
//  -o-animation-iteration-count:infinite;
//  -ms-animation-iteration-count:infinite;
//  -webkit-animation-iteration-count:infinite;
//  -moz-animation-iteration-count:infinite;
//  animation-direction:normal;
//  -o-animation-direction:normal;
//  -ms-animation-direction:normal;
//  -webkit-animation-direction:normal;
//  -moz-animation-direction:normal;
//  border-radius:7px;
//  -o-border-radius:7px;
//  -ms-border-radius:7px;
//  -webkit-border-radius:7px;
//  -moz-border-radius:7px;
//}
//
//#circleG_1{
//  animation-delay:0.1595s;
//  -o-animation-delay:0.1595s;
//  -ms-animation-delay:0.1595s;
//  -webkit-animation-delay:0.1595s;
//  -moz-animation-delay:0.1595s;
//}
//
//#circleG_2{
//  animation-delay:0.3655s;
//  -o-animation-delay:0.3655s;
//  -ms-animation-delay:0.3655s;
//  -webkit-animation-delay:0.3655s;
//  -moz-animation-delay:0.3655s;
//}
//
//#circleG_3{
//  animation-delay:0.4685s;
//  -o-animation-delay:0.4685s;
//  -ms-animation-delay:0.4685s;
//  -webkit-animation-delay:0.4685s;
//  -moz-animation-delay:0.4685s;
//}
//
//
//
//@keyframes bounce_circleG{
//  0%{}
//
//  50%{
//    background-color:rgba(255,197,200,0.98);
//  }
//
//  100%{}
//}
//
//@-o-keyframes bounce_circleG{
//  0%{}
//
//  50%{
//    background-color:rgba(255,197,200,0.98);
//  }
//
//  100%{}
//}
//
//@-ms-keyframes bounce_circleG{
//  0%{}
//
//  50%{
//    background-color:rgba(255,197,200,0.98);
//  }
//
//  100%{}
//}
//
//@-webkit-keyframes bounce_circleG{
//  0%{}
//
//  50%{
//    background-color:rgba(255,197,200,0.98);
//  }
//
//  100%{}
//}
//
//@-moz-keyframes bounce_circleG{
//  0%{}
//
//  50%{
//    background-color:rgba(255,197,200,0.98);
//  }
//
//  100%{}
//}

@media all and (min-width: 768px) {
  button.button,
  a.button {
    &.more {
      @include fs-lh(16);
      &::after {
        background-size: 12px 12px;
        height: 12px;
        width: 12px;
      }
    }
  }
}
