@mixin fs-lh($fs: 14, $lh: 22) {
  // fs: 10, 12, 14, 16

  @if $fs == 10 {
    font-size: 10px;
    line-height: 15px;
  } @else if $fs == 12 {
    font-size: 12px;
    line-height: 18px;
  } @else if $fs == 14 {
    font-size: 15px;
    line-height: 22px;
  } @else if $fs == 16 {
    font-size: 16px;
    line-height: 24px;
  } @else if $fs == 18 {
    font-size: 20px;
    line-height: 30px;
  } @else if $fs == 20 {
    font-size: 22px;
    line-height: 32px;
  } @else if $fs == 22 {
    font-size: 24px;
    line-height: 36px;
  } @else if $fs == 24 {
    font-size: 26px;
    line-height: 38px;
  } @else {
    font-size: $fs;
    line-height: $lh;
  }
}

@mixin font-regular() {
  font-weight: $regular;
}

@mixin font-bold() {
  font-weight: $bold;
}

@mixin arrow-directions($rotateToRight: 0deg) {
  transition: transform ease-in 100ms;
  transform: rotate($rotateToRight);
  &.arrow-right {
    transform: rotate(0deg);
  }
  &.arrow-left {
    transform: rotate(180deg);
  }
  &.arrow-up {
    transform: rotate(-90deg);
  }
  &.arrow-down {
    transform: rotate(90deg);
  }
}

@mixin hide-scrollbar() {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
